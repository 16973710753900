import { cloneDeep, isEmpty, isEqual } from "lodash";
import { PolicyApiClient } from "Policy/PolicyApiClient";
import { StringUtil, UriUtil } from "Util/Helpers";
import Logger from "Util/Logger";
import { QuestionaryHelper } from "Util/QuestionaryHelper";
import { ValidationHelper } from "Util/ValidationHelper";
import * as OrderStatusEnum from "Model/OrderStatus";
import * as PolicyActions from "./PolicyActions";

const logger = new Logger("PolicyThunks");

function setSecureIdUrlParam(secureId) {
  if (StringUtil.isNullOrEmpty(secureId)) {
    return;
  }

  UriUtil.replaceUrlParam("id", secureId);
}

function resolveAllowSave(po, currState) {
  let permittedActions = currState.order.orderVolatileData.PermittedActions;

  if (StringUtil.isNullOrEmpty(permittedActions)) {
    permittedActions = po.PermittedActions;
  }

  const allowSave = StringUtil.contains(permittedActions, "Save");

  return allowSave;
}

export function saveOrder(po, submitOrder) {
  return async function (dispatch, getState) {
    const currState = getState();

    const allowSave = resolveAllowSave(po, currState);

    if (!allowSave) {
      logger.debug("save is not in permitted actions");

      return;
    }

    dispatch({ type: PolicyActions.setIsSaving.type, payload: true });
    dispatch({ type: PolicyActions.clearActionMessage.type });

    const secureId = currState.order.orderVolatileData.SecureId;
    const status = currState.order.orderVolatileData.Status;
    //Cloning as the object is read-only and causes write error
    const _po = cloneDeep(po);

    if (!StringUtil.isNullOrEmpty(secureId)) {
      _po.SecureId = secureId;
      _po.Status = status;
    }

    _po.Premium = currState.order.premium;

    const changes = currState.order.orderChanges;

    const resp = await PolicyApiClient.savePolicy(_po, changes, submitOrder);

    dispatch({ type: PolicyActions.setIsSaving.type, payload: false });

    if (resp.hasError) {
      dispatch({
        type: PolicyActions.setActionMessage.type,
        payload: { type: "error", message: resp.errorMessage },
      });

      if (!resp.hasValidationResult) {
        return;
      }
    }

    const respData = resp.data;

    const savedOrder = respData.PolicyOrder;

    const validationMap = ValidationHelper.getValidationMap(respData);

    dispatch({
      type: PolicyActions.setValidationResult.type,
      payload: { validationMap },
    });

    dispatch({
      type: PolicyActions.setOrderVolatileData.type,
      payload: savedOrder,
    });

    if (respData.Saved) {
      logger.info("order saved");

      dispatch({ type: PolicyActions.setJustSaved.type, payload: true });
    }

    if (savedOrder.PolicyMap)
      if (!isEqual(currState.order.policyMap, savedOrder.policyMap))
        dispatch({
          type: PolicyActions.setPolicyMap.type,
          payload: savedOrder.PolicyMap,
        });

    setSecureIdUrlParam(savedOrder.SecureId);

    dispatch({
      type: PolicyActions.setActionMessage.type,
      payload: { type: respData.Status, message: respData.StatusDescription },
    });

    setTimeout(function () {
      ValidationHelper.scrollToFirstError(respData);
    }, 1000);
  };
}

export function autoSave(po) {
  return async function (dispatch, getState) {
    const currState = getState();

    const allowSave = resolveAllowSave(po, currState);

    if (!allowSave) {
      logger.debug("save is not in permitted actions");

      return;
    }

    if (currState.order.isSaving) {
      logger.debug("auto-save: already saving. skipping.");

      return;
    }

    if (currState.order.orderChangesCount === 0) {
      logger.debug("no changes. skipping auto-save.");

      return;
    }

    dispatch({ type: PolicyActions.setIsSaving.type, payload: true });

    const secureId = currState.order.orderVolatileData.SecureId;
    const status = currState.order.orderVolatileData.Status;

    //Cloning as the object is read-only and causes write error
    const _po = cloneDeep(po);

    if (!StringUtil.isNullOrEmpty(secureId)) {
      _po.SecureId = secureId;
      _po.Status = status;
    }

    _po.Premium = currState.order.premium;

    const changes = currState.order.orderChanges;

    const resp = await PolicyApiClient.savePolicy(_po, changes, false);

    dispatch({ type: PolicyActions.setIsSaving.type, payload: false });

    if (resp.hasError) {
      if (!resp.hasValidationResult) {
        return;
      }
    }

    const respData = resp.data;

    const savedOrder = respData.PolicyOrder;

    dispatch({
      type: PolicyActions.setOrderVolatileData.type,
      payload: savedOrder,
    });

    if (respData.Saved) {
      logger.info("order auto-saved");
    }

    if (savedOrder.PolicyMap)
      if (!isEqual(currState.order.policyMap, savedOrder.PolicyMap))
        dispatch({
          type: PolicyActions.setPolicyMap.type,
          payload: savedOrder.PolicyMap,
        });

    setSecureIdUrlParam(savedOrder.SecureId);
  };
}

export function loadProjectProperty(event) {
  return async function (dispatch, getState) {
    const currState = getState();

    if (
      !currState.order.loadProjectPropertyEvent ||
      isEmpty(currState.order.loadProjectPropertyEvent)
    ) {
      logger.debug(
        "loadProjectPropertyEvent is not specified. skipping project reload."
      );
      return;
    }

    const resp = await PolicyApiClient.loadProjectProperty(event.NHPCode);

    if (resp.hasError && !resp.hasValidationResult) {
      logger.error("error loading questionary: " + resp.errorMessage);

      return;
    }

    const respData = resp.data;

    const validationMap = ValidationHelper.getValidationMap(respData);

    if (validationMap && !isEmpty(validationMap)) {
      dispatch({
        type: PolicyActions.setValidationResult.type,
        payload: { validationMap },
      });
    } else {
      const indexer = QuestionaryHelper.getIndexer(event.TriggeringField);
      const property = respData.Property;

      let shadowChanges = {};

      Object.keys(property).forEach((key) => {
        if (
          StringUtil.isEither(
            key,
            "TransactionType",
            "Id",
            "NHPCode",
            "IsEnrolledInNHP"
          )
        )
          return;

        const fieldName = `${indexer}${key}`;
        const fieldValue = property[key];

        shadowChanges[fieldName] = fieldValue;

        dispatch({
          type: PolicyActions.changeOrder.type,
          payload: { fieldName, fieldValue },
        });
      });

      //#region Project Name
      {
        const key = "ProjectName";
        const fieldName = `${indexer}${key}`;
        const fieldValue = property[key];

        shadowChanges[fieldName] = fieldValue;

        dispatch({
          type: PolicyActions.changeOrder.type,
          payload: { fieldName, fieldValue },
        });
      }
      //#endregion Project Name

      //#region PIN Prefix
      {
        const key = "PINPrefix";
        const fieldName = `${indexer}${key}`;
        const fieldValue = property[key];

        shadowChanges[fieldName] = fieldValue;

        dispatch({
          type: PolicyActions.changeOrder.type,
          payload: { fieldName, fieldValue },
        });
      }
      //#endregion PIN Prefix

      //#region Additional Project Information

      {
        const key = "AdditionalProjectInformation";
        const fieldName = `${indexer}${key}`;
        const fieldValue = property[key];

        shadowChanges[fieldName] = fieldValue;

        dispatch({
          type: PolicyActions.changeOrder.type,
          payload: { fieldName, fieldValue },
        });
      }
      //#endregion Additional Project Information

      dispatch({
        type: PolicyActions.shadowUpdateOrder.type,
        payload: { propertyInfo: shadowChanges },
      });
    }
  };
}

export function loadQuestionary(event) {
  return async function (dispatch, getState) {
    const currState = getState();

    if (
      !currState.order.reloadQuestionaryEvent ||
      isEmpty(currState.order.reloadQuestionaryEvent)
    ) {
      logger.debug(
        "reloadQuestionaryEvent is not specified. skipping quertionary reload."
      );
      return;
    }

    const resp = await PolicyApiClient.loadQuestionary(
      event.QuestionaryId,
      event.QuestionaryVersion,
      event.TransactionType,
      event.PropertyType,
      event.IsEnrolledInNHP,
      event.NHPCode,
      event.PropertyAddressProvince,
      event.AddExistingOwnerPolicy,
      event.ExistingOwnerHasTIPolicy
    );

    if (resp.hasError && !resp.hasValidationResult) {
      logger.error("error loading questionary: " + resp.errorMessage);
      return;
    }

    const respData = resp.data;
    logger.debug(`loadQuestionary::respData ${JSON.stringify(respData)}`);
    const validationMap = ValidationHelper.getValidationMap(respData);

    if (validationMap && !isEmpty(validationMap)) {
      dispatch({
        type: PolicyActions.setValidationResult.type,
        payload: { validationMap },
      });
    } else {
      dispatch({
        type: PolicyActions.setQuestionary.type,
        payload: respData.Questionary,
      });
    }
  };
}

export function changeOrderStatus(po, orderStatus) {
  return async function (dispatch, getState) {
    const currState = getState();

    // do we need to check for a permitted action? which one?

    dispatch({ type: PolicyActions.setIsSaving.type, payload: true });
    dispatch({ type: PolicyActions.clearActionMessage.type });

    const secureId = currState.order.orderVolatileData.SecureId;

    const resp = await PolicyApiClient.changeStatus(secureId, orderStatus);

    dispatch({ type: PolicyActions.setIsSaving.type, payload: false });

    if (resp.hasError) {
      dispatch({
        type: PolicyActions.setActionMessage.type,
        payload: { type: "error", message: resp.errorMessage },
      });

      if (!resp.hasValidationResult) {
        return;
      }
    }

    const respData = resp.data;

    const savedOrder = respData.PolicyOrder;

    const validationMap = ValidationHelper.getValidationMap(respData);

    dispatch({
      type: PolicyActions.setValidationResult.type,
      payload: { validationMap },
    });

    dispatch({
      type: PolicyActions.setActionMessage.type,
      payload: { type: respData.Status, message: respData.StatusDescription },
    });

    dispatch({
      type: PolicyActions.setOrderVolatileData.type,
      payload: savedOrder,
    });

    setTimeout(function () {
      ValidationHelper.scrollToFirstError(respData);
    }, 1000);
  };
}

export function registerPolicy() {
  return async function (dispatch, getState) {
    const currState = getState();

    return;

    // KENNETH -- Approved status was removed, what do we do here?

    const allowRegistration = StringUtil.isEqual(
      currState.order.orderVolatileData.Status,
      OrderStatusEnum.Submitted
    );

    if (!allowRegistration) {
      logger.debug("policy registration not permitted");
      return;
    }

    const secureId = currState.order.orderVolatileData.SecureId;
    const resp = await PolicyApiClient.registerPolicy(secureId);

    dispatch({ type: PolicyActions.setIsSaving.type, payload: false });

    if (resp.hasError) {
      dispatch({
        type: PolicyActions.setActionMessage.type,
        payload: { type: "error", message: resp.errorMessage },
      });

      if (!resp.hasValidationResult) {
        return;
      }
    }

    const respData = resp.data;

    const validationMap = ValidationHelper.getValidationMap(respData);

    dispatch({
      type: PolicyActions.setValidationResult.type,
      payload: { validationMap },
    });

    const registrationStatus = respData.RegistrationStatus;

    if (!StringUtil.isNullOrEmpty(registrationStatus)) {
      logger.info("order registered in is4w");

      const po = cloneDeep(currState.order.orderVolatileData);

      po.RegistrationStatus = registrationStatus;

      dispatch({ type: PolicyActions.setOrderVolatileData.type, payload: po });

      dispatch({
        type: PolicyActions.setActionMessage.type,
        payload: { type: respData.Status, message: respData.StatusDescription },
      });
    }
  };
}

export async function cloneOrder(inputParams) {
  const resp = await PolicyApiClient.clonePolicy(inputParams);
  if (resp.hasError) {
    if (!resp.hasValidationResult) {
      return;
    }
  }

  const respData = resp.data;

  const savedOrder = respData.PolicyOrder;

  return savedOrder;
}
